<template>
    <div class="upsell-wrapper" @afterLeave="destroySlider">
        <Modal ref="modal">
            <template #content>
                <p class="upsell-title h4">Sprawdź podobne produkty</p>
                <p class="upsell-subtitle">
                    Podobne do {{ upsellData.product_name }}
                </p>
                <CrossSell
                    v-if="upsellData.product_upsell"
                    ref="upsell"
                    type="upsell"
                    :items="upsellData.product_upsell"
                />
            </template>
            <template
                v-if="upsellData.category_url && upsellData.category_name"
                #footer
            >
                <p>
                    Zobacz więcej produktów:
                    <a :href="upsellData.category_url">
                        {{ upsellData.category_name }}
                    </a>
                </p>
            </template>
        </Modal>
    </div>
</template>

<script>
import axios from 'axios'
import Modal from '../partials/Modal.vue'
import CrossSell from './CrossSell.vue'

export default {
    components: {
        Modal,
        CrossSell
    },
    props: {
        // array of upsell products
        items: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            slider: null,
            upsellData: {}
        }
    },
    mounted() {
        Array.from(
            document.querySelectorAll('.product-item__unavailable-link')
        ).forEach((similarLink) => {
            similarLink.addEventListener(
                'click',
                (event) => {
                    event.preventDefault()
                    this.getUpsellData(
                        event.currentTarget.getAttribute('data-url')
                    ).then(() => this.$refs.modal.OpenModal())
                },
                false
            )
        })
    },
    methods: {
        getUpsellData(url) {
            return axios.get(url).then((response) => {
                this.upsellData = response.data
            })
        },
        destroySlider() {
            this.$refs.upsell.destroyCrossSellSlider()
        }
    }
}
</script>

<style lang="scss">
.upsell-wrapper {
    .custom-modal-wrapper {
        text-align: center;

        .upsell-title {
            color: $sherpa-blue;
            font-size: 26px;
            text-transform: uppercase;
        }

        .upsell-subtitle {
            margin-bottom: 30px;
            font-weight: 600;
            font-size: 17px;
        }

        .cart-crosssell {
            padding-top: 0;
            background-color: transparent;

            &__title {
                display: none;
            }

            &__items {
                padding-bottom: 0;
            }

            &__item {
                margin: 0 15px;
            }
        }

        .custom-modal-footer {
            background-color: #f4f4f4;
            min-height: 85px;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;

            p {
                margin: 0;
                padding: 0 10px;
                font-weight: 600;

                a {
                    color: $sherpa-blue;
                    margin-left: 6px;
                }
            }
        }
    }
}
</style>
