var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upsell-wrapper", on: { afterLeave: _vm.destroySlider } },
    [
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [
                  _c("p", { staticClass: "upsell-title h4" }, [
                    _vm._v("Sprawdź podobne produkty")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "upsell-subtitle" }, [
                    _vm._v(
                      "\n                Podobne do " +
                        _vm._s(_vm.upsellData.product_name) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.upsellData.product_upsell
                    ? _c("CrossSell", {
                        ref: "upsell",
                        attrs: {
                          type: "upsell",
                          items: _vm.upsellData.product_upsell
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            },
            _vm.upsellData.category_url && _vm.upsellData.category_name
              ? {
                  key: "footer",
                  fn: function() {
                    return [
                      _c("p", [
                        _vm._v(
                          "\n                Zobacz więcej produktów:\n                "
                        ),
                        _c(
                          "a",
                          { attrs: { href: _vm.upsellData.category_url } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.upsellData.category_name) +
                                "\n                "
                            )
                          ]
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }